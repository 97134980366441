<template>
  <div>
    <v-container fluid>

      <div class="main">

        <div class="d-lg-flex d-block justify-space-between my-3">
          <v-spacer/>
          <v-btn class="rounded-lg"
                 color="gifty"
                 dark
                 depressed
                 to="/supplier-manager-services/create">
            <v-icon left>mdi-plus</v-icon>
            Ajouter
          </v-btn>
        </div>

        <v-card class="rounded-lg shadow">

          <v-overlay :value="overlay"
                     absolute
                     color="white"
                     opacity="0.5">
            <v-progress-circular
                color="primary"
                indeterminate
                size="50"
            ></v-progress-circular>
          </v-overlay>

          <v-card-title>
            <div>
              <v-text-field
                  v-model="keyword"
                  append-icon="mdi-magnify"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Recherche..."
                  rounded
                  single-line
              ></v-text-field>
            </div>

            <v-spacer/>

            <v-btn color="grey"
                   text>
              <v-icon left>mdi-filter</v-icon>
              Filtre
            </v-btn>

            <v-btn color="grey"
                   icon
                   @click="getServices">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

          </v-card-title>

          <v-divider/>

          <v-card-text v-if="isLoading" class="pa-0">
            <v-skeleton-loader type="table"/>
          </v-card-text>

          <div v-else>

            <v-card-text class="pa-0">

              <div v-if="services.length">

                <v-data-table :headers="headers" :items="services" hide-default-footer disable-sort>

                  <template v-slot:item.visible="{ item }">
                    <v-switch hide-details class="pa-0 ma-0" readonly color="success" v-model="item.visible"></v-switch>
                  </template>


                  <template v-slot:item.image="{ item }">
                    <v-avatar :color="item.background_color" rounded size="40">
                      <v-img :src="getImageLink(item.image)" contain></v-img>
                    </v-avatar>
                  </template>


                  <template v-slot:item.top_service="{ item }">
                    <v-icon color="success" v-if="item.top_service">mdi-check-circle-outline</v-icon>
                    <v-icon color="red" v-else>mdi-close-circle-outline</v-icon>
                  </template>


                  <template v-slot:item.has_sms="{ item }">
                    <v-icon color="success" v-if="item.has_sms">mdi-check-circle-outline</v-icon>
                    <v-icon color="red" v-else>mdi-close-circle-outline</v-icon>
                  </template>

                  <template v-slot:item.with_build_vouchers="{ item }">
                    <v-icon color="success" v-if="item.with_build_vouchers">mdi-check-circle-outline</v-icon>
                    <v-icon color="red" v-else>mdi-close-circle-outline</v-icon>
                  </template>

                  <template v-slot:item.has_account="{ item }">
                    <v-icon color="success" v-if="item.has_account">mdi-check-circle-outline</v-icon>
                    <v-icon color="red" v-else>mdi-close-circle-outline</v-icon>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <div class="d-flex">
                      <v-btn icon :to="'/supplier-manager-services/'+item.id + '/update'">
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>

                      <v-btn icon>
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </div>
                  </template>


                  <template v-slot:item.state="{ item }">
                    <v-chip small dark color="success" v-if="item.state.name === 'Active'">
                      <v-icon left small>mdi-check</v-icon>
                      {{ item.state != null ? item.state.name : "-" }}
                    </v-chip>
                    <v-chip color="red"  small dark v-else>
                      <v-icon left small>mdi-close</v-icon>
                      {{ item.state != null ? item.state.name : "-" }}
                    </v-chip>
                  </template>

                </v-data-table>

              </div>

              <div v-else>
                <NoResults @reload="getServices"/>
              </div>

            </v-card-text>

            <v-divider v-if="total > 10"/>

            <v-card-actions v-if="total > 10">

              <div :style="{width : '120px'}">
                <v-select v-model="perPage"
                          :items="[10,20,30,50,100]"
                          dense
                          hide-details
                          label="Ligne par page"
                          outlined
                ></v-select>
              </div>

              <v-spacer/>

              <v-pagination v-if="total > 10"
                            v-model="pagination.current"
                            :length="pagination.total"
                            circle
                            total-visible="8"
                            @input="getServices"></v-pagination>

            </v-card-actions>

          </div>

        </v-card>

      </div>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";

export default {
  components: {
    NoResults,
  },
  data() {
    return {
      isLoading: false,
      overlay: false,
      keyword: null,

      services: [],
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },
      headers: [
        {text: 'Visibilité', value: 'visible'},
        {text: 'Nom', value: 'name'},
        {text: 'Image', value: 'image'},
        {text: 'Type', value: 'type.name'},
        {text: 'State', value: 'state'},
        {text: 'Fournisseur', value: 'supplier.name'},
        {text: 'Génération dynamique', value: 'with_build_vouchers'},
        {text: 'Top service', value: 'top_service'},
        {text: 'SMS', value: 'has_sms'},
        {text: 'Compte', value: 'has_account'},
        {text: 'Actions', value: 'actions'},
      ],
    };
  },
  methods: {
    getServices() {
      this.isLoading = true
      HTTP.get('/v2/supplier-manager/services?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          keyword: this.keyword
        }
      }).then(res => {
        this.isLoading = false
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.services = res.data.data.data

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    }
  },
  created() {
    this.getServices()
  },
  watch: {
    perPage() {
      this.getServices()
    },
    keyword() {
      this.getServices()
    }
  },
};
</script>

<style scoped></style>
